<template>
    <div class="border-1">
        <div class="h3 mb-3">Как собирать данные?</div>

        <div v-if="expanded" class="d-inline-block mt-2">

            <div class="h3 mt-4">Шаг 1</div>
            <p>Для получения форм сбора результатов Вам необходимо скачать программу Адаптер ФГИС ФИС ОКО из <a href="https://lk-fisoko.obrnadzor.gov.ru/" target="_blank">Личного кабинета ФИС ОКО</a>.</p>

            <v-divider class="mt-4" />

            <div class="h3 mt-4">Шаг 2</div>
            <p>Для установки приложения запустите скачанный на предыдущем шаге файл и следуйте инструкциям мастера установки.</p>
            <p>После завершения установки на Рабочем столе появится ярлык «Адаптер». Используйте его для запуска приложения.</p>

            <v-divider class="mt-4" />

            <div class="h3 mt-4">Шаг 3</div>
            <p>После запуска приложения Вам нужно будет ввести логин и пароль от системы ФГИС ФИС ОКО для продолжения работы.</p>
            <img :src="images[0]" />

            <v-divider class="mt-4" />

            <div class="h3 mt-4">Шаг 4</div>
            <p>Главное окно приложения отображает список доступных для Вас отчётов, статус отчёта в системе ФГИС ФИС ОКО и кнопки для скачивания шаблона отчета.</p>
            <img :src="images[1]" />

            <v-divider class="mt-4" />

            <div class="h3 mt-4">Шаг 5</div>
            <p>Нажмите на кнопку <img :src="images[2]" class="mb-n2" /> и выберите формат json.</p>
            <img :src="images[3]" class="mb-4" />
            <p>Для получения шаблона для заполнения выбранного отчёта нажмите кнопку «Скачать ↑» в строке напротив отчёта. Выберите формат скачиваемого файла json.
                В появившемся диалоге сохранения файла шаблона подтвердите сохранение файла в удобное Вам место.</p>

            <v-divider class="mt-4" />
            
            <div class="h3 mt-4">Шаг 6</div>
            <p>Нажмите на кнопку «Импорт процедуры» под этой инструкцией и выберите файл, сохраненный на предыдущем шаге в открывшемся окне. После добавления появится процедура, для ее заполнения нажмите на <img :src="images[4]" class="mb-n3" /></p>
            
        </div>
        <a class="color-primary fs-14 d-flex align-center" @click.prevent.stop="$emit('update:expanded', !expanded)">
            {{ expanded ? 'Свернуть' : 'Развернуть'}}
            <icon :name="expanded ? 'triangle-up' : 'triangle-down'" :color="$const.color.primary" />
        </a>
    </div>
</template>

<script>
import image1 from '@/assets/img/fisoko-info/1.png'
import image2 from '@/assets/img/fisoko-info/2.png'
import image3 from '@/assets/img/fisoko-info/3.png'
import image4 from '@/assets/img/fisoko-info/4.png'
import image5 from '@/assets/img/fisoko-info/5.png'
import Icon from '@/components/icons/Index.vue'

export default {
    components: { Icon },
    props: {
        expanded: { type: Boolean, default: false }
    },
    data () {
        return {
            currentStep: 1,
            stepsCount: 6
        }
    },
    computed: {
        images () {
            return [image1, image2, image3, image4, image5]
        }
    },
    methods: {
        next () {
            if (this.currentStep < this.stepsCount)
                this.currentStep++
        },
        back () {
            if (this.currentStep > 1)
                this.currentStep--
        }
    }
}
</script>