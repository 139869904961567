<template>
    <div class="d-flex flex-column">
        <v-btn
            outlined
            height="40px"
            class="button-stylized-blue"
            :loading="loading"
            @click.prevent.stop="onClick"
        >
            <icon color="white" name="folder-down" class="mr-2" />
            <span>Импорт процедур&nbsp;(JSON или XML)</span>
        </v-btn>
    </div>
</template>

<script>
import { xml2json } from 'xml-js'
import unzip from 'unzip-js'
import Icon from '@/components/icons/Index.vue'

export default {
    components: { Icon },
    props: {
        hint: { type: Boolean, default: false }
    },
    data () {
        return {
            loading: false
        }
    },
    methods: {
        concatenateUint8Arrays(array1, array2) {
            // Создаем новый Uint8Array, длина которого равна сумме длин двух массивов
            const concatenatedArray = new Uint8Array(array1.length + array2.length)

            // Копируем данные из первого массива
            concatenatedArray.set(array1, 0)

            // Копируем данные из второго массива, начиная с позиции array1.length
            concatenatedArray.set(array2, array1.length)

            return concatenatedArray
        },
        async extractFiles (file) {

            const extentionToMimeType = {
                'json': 'application/json',
                'xml': 'text/xml',
                'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            const extractedFiles = []
            return new Promise (resolve => {
                
                unzip(file, (err, zipFile) => {
                
                    if (err) {
                        console.error(err)
                        return resolve({ success: false, error: 'Невозможно прочитать файл неизвестной структуры' })
                    }

                    zipFile.readEntries((err, entries) => {

                        if (err) {
                            console.error(err)
                            return resolve({ success: false, error: `Ошибка чтения файла #A1` })
                        }

                        entries.forEach(entry => {
                            zipFile.readEntryData(entry, false, (err, readStream) => {
                                
                                if (err) {
                                    console.error(err)
                                    return resolve({ success: false, error: `Ошибка чтения файла #A2` })
                                }

                                let entryData = null

                                readStream.on('data', chunk => {
                                    if (entryData === null)
                                        entryData = chunk
                                    else
                                        entryData = this.concatenateUint8Arrays(entryData, chunk)
                                })
                                readStream.on('error', err => {
                                    console.error(err)
                                    return resolve({ success: false, error: `Ошибка чтения файла #A3` })
                                })
                                readStream.on('end', async () => {
                                    readStream?.close?.()
                                    const mimeType = extentionToMimeType[entry.name.split('.').pop()]
                                    extractedFiles.push(
                                        this.createFile(
                                                        {
                                                            content: entryData,
                                                            type: mimeType,
                                                            name: entry.name
                                                        }
                                                    )
                                    )

                                    if (extractedFiles.length === entries.length)
                                        resolve({ success: true, data: extractedFiles })
                                })
                            })
                        })
                    })
                })
            })
        },
        async onClick () {

            let archiveFile = await this.selectFile(['.json', '.xml'])
            
            if (!archiveFile)
                throw new Error('File selection canceled.')

            const { success, error, data } = await this.extractFiles(archiveFile)

            if (!success) 
                return this.$root.$emit('snack-bar', { text: `Импорт прерван: ${error}`})

            const excelFile = data[data.findIndex(file => file.name.endsWith('.xlsx'))]
            const jsonFile = data[data.findIndex(file => file.name.endsWith('.json'))]
            const xmlFile = data[data.findIndex(file => file.name.endsWith('.xml'))]
            let structureFile = jsonFile || xmlFile

            if (!structureFile)
                return this.$root.$emit('snack-bar', { text: `Импорт прерван: Ошибка чтения файла #B1`})

            if (!excelFile)
                return this.$root.$emit('snack-bar', { text: `Импорт прерван: Ошибка чтения файла #B2`})
            
            if (structureFile.type === 'text/xml') {
                let fileContent = await structureFile.text()

                if (!fileContent)
                    return this.$root.$emit('snack-bar', { text: `Импорт прерван: Ошибка чтения файла #B3`})

                // Replace digit named tags to avoid errors on parsing xml
                fileContent = fileContent.replace(/<(\d)>/gm, `<x-$1>`)
                fileContent = fileContent.replace(/<\/(\d)>/gm, `</x-$1>`)
                
                const result = JSON.parse(xml2json(fileContent, { compact: false, spaces: 4, nativeType: true, ignoreDeclaration: true }))
                const transformedData = result.elements[0].elements.map(report => this.normalizeTransformedReport(report.elements))
                
                if (!transformedData)
                    return this.$root.$emit('snack-bar', { text: `Импорт прерван: Ошибка чтения файла #B4`})

                structureFile = await this.createFile({
                    content: JSON.stringify(transformedData),
                    type: 'text/json',
                    name: structureFile.name.replace('.xml', '.json')
                })
            }

            const result = await this.importDataCollection(structureFile, excelFile)

            this.$emit('sent', result)
        },
        normalizeTransformedReport (elements) {
            if (!elements)
                throw new Error('Missed object for proccessing.')

            return elements.reduce((result, item) => {

                if (!(item.elements && item.elements.length > 0))
                    return result

                // Replace 'x-' prefix in names that have been used to avoid problems with digit named tags
                const key = item.name.replace('x-', '').replace('Н', 'не проид.')
                const value = item.elements.length === 1 && !_.isNil(item.elements[0]?.text) ?
                                item.elements[0].text :
                                this.normalizeTransformedReport(item.elements)

                if (!_.isNil(result[key]) && !Array.isArray(result[key]))
                    result[key] = [result[key]]

                if (Array.isArray(result[key]))
                    result[key].push(value)
                else
                    result[key] = value
                
                return result
                
            }, {})
        },
        selectFile (acceptedExt = ['.json', '.xml']) {
            return new Promise(resolve => {
                // Создаем элемент input
                const input = document.createElement('input')
                input.type = 'file'
                input.accept = acceptedExt.join(',') // Разрешаем только файлы с указанным расширением 
                
                // Добавляем обработчик события на изменение (выбор файла)
                input.onchange = (event) => {
                    const file = event.target.files[0]
                    resolve(file || null)
                };

                // Имитация клика
                input.click()
            })
        },
        createFile ({ content = '', type = 'text/plain', name = 'file.txt' }) {
            
            const blob = new Blob([content], { type })
            return new File([blob], name, { type: blob.type })
        },
        async importDataCollection (structureFile, excelFile) {
            let succeed = true
            let errors = null
            try {
                this.loading = true
                
                const form = new FormData()
                form.append('file', structureFile)
                form.append('excel_file', excelFile)
                
                const { success, error } = await this.$store.dispatch('fioko_data_collection/import', form)

                if (!success) {
                    errors = error
                    throw new Error(error)
                }
                
            } catch (e) {
                console.error(e)
                succeed = false
            } finally {
                this.loading = false
            }
            return { success: succeed, errors }
        }
    }
}
</script>